import React from 'react'
import Layout from '../layouts/layout'
import SEO from '../components/seo'
import SecondaryNav from '../components/secondarynav'

import BorderedBox from '../components/borderedbox'
import TitleHeader from '../components/titleheader'
import Card from '../components/card'

// Images
import corporateimage1 from '../assets/images/corporate-responsibility/Corporate_Responsibility_ReponsibleSourcing_03.jpg'
import corporateimage2 from '../assets/images/environmental-lp/environment_grow_box_d.png'
import corporateimage3 from '../assets/images/environmental-lp/environment_reduce_box_d.png'
import corporateimage10 from '../assets/images/environmental-lp/Color_Member_Logo.jpg'

import corporateimage11 from '../assets/images/esg_2021/zdhc-vector-logo@3x.png'

import corporateimage12 from '../assets/images/esg_2021/CanopyStyle.png'
import corporateimage13 from '../assets/images/esg_2021/Pack4Good.png'
import corporatesourcingimage7 from '../assets/images/corporate-responsibility/Cascale_FullColor_Positive.png'
import corporatesourcingimage8 from '../assets/images/corporate-responsibility/BC_Member_Logo_Green-Blue.jpg'
import corporatesourcingimage9 from '../assets/images/corporate-responsibility/afirm-group-logo-tm_blue.png'
import How2Recycle from '../assets/images/corporate-responsibility/How2Recycle.png'
import SBTi from '../assets/images/corporate-responsibility/SBTi.svg'
import SPC from '../assets/images/corporate-responsibility/SPC.png'
import vendorenvironmentalpolicy2021 from '../assets/images/corporate-responsibility/vendorenvironmentalpolicy2021.pdf'
import esgPdf2022 from '../assets/images/TCP-2022-ESG-Report-Final.pdf'

import newCorpEnv2021 from '../assets/images/newCorpEnv2021.pdf'
import internalesgdk from '../assets/images/esg_2021/esg_lp_header_dk.jpg'
import internalesgmb from '../assets/images/esg_2021/esg_lp_header_mobile.jpg'
import pressdk from '../assets/images/esg_2021/dk-press.png'
import pressmb from '../assets/images/esg_2021/mb-press.png'
import TCPCanopyStyle from '../assets/images/TCP_CanopyStyle_and_Pack4Good__Pledge.pdf'

import { trackPageData, getPageDetails } from '../analytics'

class ResponsibleSourcing extends React.PureComponent {
  constructor(props) {
    super(props)

    let dataManager = {
      pageDetails: {},
    }
    if (typeof window !== `undefined`) {
      if (window.dataManager) {
        dataManager = window.dataManager
      }
    }
    dataManager.pageDetails = getPageDetails()
  }

  componentDidMount() {
    trackPageData()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Environment | The Children's Place Corporate Website"
          description="The Children's Place is committed to responsible sourcing practices in our supply chain. Learn more on The Children's Place Corproate Website."
        />
        <div
          style={{
            position: `relative`,
          }}
          class="row"
        >
          <a href={`${esgPdf2022}`} target="_blank">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <TitleHeader
                image={internalesgdk}
                mbheaderimage={internalesgmb}
                title="ENVIRONMENT, SOCIAL &amp; GOVERNANCE"
                addClass="corporateTitle esgTitle"
              />
            </div>
          </a>
          <br />

          <a
            class="esg-postion-lb"
            href={`https://newsplace.childrensplace.com/the-childrens-place-issues-2021-annual-esg-report/`}
            target="_blank"
          >
            <div class="col-12">
              <img src={pressdk} className="img-fluid hidden-xs" alt="" />
              <img src={pressmb} className="img-fluid visible-xs" alt="" />
            </div>
          </a>
        </div>

        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <SecondaryNav
              active="Environment"
              name1="Environment"
              link1="/environment"
              name2="Social"
              link2="/social"
              name3="Governance"
              link3="/governance"
            />
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <BorderedBox>
              <h2>ENVIRONMENTAL INITIATIVES AT THE CHILDREN’S PLACE</h2>
              <p>
                At The Children’s Place, we believe it is important that we do
                our part in contributing to the preservation of the planet for
                future generations. We are committed to having a positive
                environmental impact through responsible business and sourcing
                practices. We work throughout our operations and with the global
                third-party vendors who manufacture our product in the following
                focus areas:
              </p>
              <ul class="list-inline list-bullet text-left">
                <li>
                  <b>CLIMATE + ENERGY</b> Reduce greenhouse gas emissions across
                  our global operations and supply chain
                </li>
                <li>
                  <b>RAW MATERIALS</b> Increase the use of more sustainable
                  materials in our products
                </li>
                <li>
                  <b>WATER STEWARDSHIP</b> Work with vendors to reduce water
                  consumption in manufacturing and processing
                </li>
                <li>
                  <b>CHEMICAL MANAGEMENT</b> Support implementation of
                  responsible chemical management and wastewater systems
                </li>
                <li>
                  <b>WASTE</b> Divert the amount of our waste sent to landfill
                </li>
                <li>
                  <b>CIRCULARITY</b> Help to avert product and material disposal
                  through reuse and recycling
                </li>
              </ul>

              <p>
                Details on these focus areas are available in our{' '}
                <a target="_blank" href={`${esgPdf2022}#page=20`}>
                  2022 Environment, Social &amp; Governance Report
                </a>
                .
              </p>
            </BorderedBox>
          </div>
        </div>

        <div id="sourcinggroup">
          {/* safe working */}
          <div class="row mb-5 text-center">
            <div class="col-md-6 col-sm-6 col-xs-6 fill d-md-none">
              <img
                src={corporateimage2}
                title=""
                className="img-fluid"
                alt="..."
              />
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6">
              <Card
                title="GOALS"
                description="Our industry is increasingly being challenged to make faster progress to help protect the environment. We are working with our partners to meet this challenge in our company’s operations and in the communities where we do business. We have set public environmental targets to demonstrate transparency and accountability to our commitments, and drive progress in our strategic focus areas."
              >
                <a target="_blank" href={`${esgPdf2022}#page=12`}>
                  <p>Learn more about our environmental goals</p>
                </a>

                {/* <button class="btn btn-primary more-less" type="button" data-toggle="collapse" data-target="#corpMoreone" aria-expanded="false" aria-controls=""></button> */}
              </Card>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 fill d-none d-md-flex">
              <img
                src={corporateimage2}
                title=""
                className="img-fluid"
                alt="..."
              />
            </div>
          </div>

          {/* education */}
          <div class="row mb-5 text-center">
            <div class="col-md-6 col-sm-6 col-xs-6">
              <img
                src={corporateimage3}
                title=""
                className="img-fluid"
                alt="..."
              />
            </div>

            <div class="col-md-6 col-sm-6 col-xs-6">
              <Card title="POLICIES" description="">
                <p>
                  To support our commitment to reducing the environmental
                  impacts associated with our business, we have developed
                  policies and procedures that guide the actions of our
                  associates and third-party vendors.{' '}
                </p>

                <p>
                  {' '}
                  <a target="_blank" href={newCorpEnv2021}>
                    Corporate Environmental Policy
                  </a>{' '}
                </p>

                <p>
                  {' '}
                  <a target="_blank" href={vendorenvironmentalpolicy2021}>
                    Vendor Environmental Policy
                  </a>{' '}
                </p>

                <p>
                  {' '}
                  <a target="_blank" href={TCPCanopyStyle}>
                    Commitment to Forests
                  </a>{' '}
                </p>

                {/* <button class="btn btn-primary more-less" type="button" data-toggle="collapse" data-target="#corpMoretwo" aria-expanded="false" aria-controls=""></button> */}
              </Card>
            </div>
          </div>

          <div class="row mb-5 text-center">
            <div class="col-12">
              <h3>OUR INITIATIVE PARTNERS</h3>
              <p>
                <small>
                  Learn more about our ESG partners by clicking their logos
                  below.
                </small>
              </p>
            </div>
            <div class="col-sm-4 col-12 pl-5 pr-5">
              <button
                class="btn logo-btn close-button"
                type="button"
                data-toggle="collapse"
                data-target="#DonateMoreFour"
                aria-expanded="false"
                aria-controls=""
              >
                {' '}
                <img
                  src={corporatesourcingimage7}
                  title=""
                  className="img-fluid"
                  alt="..."
                />
              </button>
            </div>

            <div class="col-sm-4 col-12 pl-5 pr-5">
              <button
                class="btn logo-btn close-button"
                type="button"
                data-toggle="collapse"
                data-target="#DonateMoreFive"
                aria-expanded="false"
                aria-controls=""
              >
                {' '}
                <img
                  src={corporatesourcingimage8}
                  title=""
                  className="img-fluid"
                  alt="..."
                />
              </button>
            </div>

            <div class="col-sm-4 col-12  ">
              <button
                class="btn logo-btn close-button text-center"
                type="button"
                data-toggle="collapse"
                data-target="#DonateMoreSix"
                aria-expanded="false"
                aria-controls=""
              >
                {' '}
                <img
                  src={corporatesourcingimage9}
                  title=""
                  className="img-fluid"
                  alt="..."
                />
              </button>
            </div>
          </div>

          <div
            class="row mb-5 collapse"
            id="DonateMoreFour"
            data-parent="#sourcinggroup"
          >
            <div class="col-md-12 col-sm-12 col-xs-12">
              {/* GOOD+ Foundation */}
              <BorderedBox classname="mb-n-border">
                <button
                  class="btn close-button"
                  type="button"
                  data-toggle="collapse"
                  data-target="#DonateMoreFour"
                  aria-expanded="false"
                  aria-controls=""
                >
                  X
                </button>
                <p>
                  Cascale brings together 300 leading apparel, footwear, and
                  textile brands, retailers, manufacturers, sourcing agents,
                  service providers, trade associations, NGOs, and academic
                  institutions – all working toward a shared vision of an
                  industry that gives back more than it takes to the planet and
                  its people.
                </p>
                <p>
                  <a target="_blank" href="https://apparelcoalition.org/">
                    Learn more about Cascale.
                  </a>
                </p>
              </BorderedBox>
            </div>
          </div>

          <div
            class="row mb-5 collapse"
            id="DonateMoreFive"
            data-parent="#sourcinggroup"
          >
            {/* IronMatt (The Matthew Larson Foundation)  */}
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button
                  class="btn close-button"
                  type="button"
                  data-toggle="collapse"
                  data-target="#DonateMoreFive"
                  aria-expanded="false"
                  aria-controls=""
                >
                  X
                </button>
                <p>
                  Working with organizations from farming, retail fashion and
                  textile brands, Better Cotton is an agricultural standard
                  system helping farmers use less water and fewer chemical
                  pesticides and fertilizers, all while improving yields,
                  profitability and worker's lives. Better Cotton is sourced via
                  a system of mass balance, which means it is not physically
                  traceable to end products. We are committed to sourcing 100%
                  of our cotton as 'more sustainable cotton' by the end of
                  fiscal 2025. 'More sustainable cotton' includes Better Cotton,
                  recycled cotton and organic cotton.
                </p>
                <p>
                  <a target="_blank" href="https://bettercotton.org/">
                    Learn more about Better Cotton.
                  </a>
                </p>
              </BorderedBox>
            </div>
          </div>

          <div
            class="row mb-5 collapse"
            id="DonateMoreSix"
            data-parent="#sourcinggroup"
          >
            {/* Youth Consultation Services (YCS)  */}
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button
                  class="btn close-button"
                  type="button"
                  data-toggle="collapse"
                  data-target="#DonateMoreSix"
                  aria-expanded="false"
                  aria-controls=""
                >
                  X
                </button>
                <p>
                  The AFIRM Group works to build safer and more sustainable
                  chemistry within the apparel and footwear supply chains. With
                  members including some of the largest brands and retailers in
                  the world, AFIRM continues to be a recognized global center of
                  excellence, providing resources to enable continuous
                  advancement of chemical management best practices.
                </p>
                <p>
                  <a target="_blank" href="https://www.afirm-group.com">
                    Learn more about The AFIRM Group.
                  </a>
                </p>
              </BorderedBox>
            </div>
          </div>

          <div class="row mb-5 text-center">
            <div class="col-sm-4 col-12 pl-5 pr-5">
              <button
                class="btn logo-btn close-button"
                type="button"
                data-toggle="collapse"
                data-target="#SPC"
                aria-expanded="false"
                aria-controls=""
              >
                {' '}
                <img src={SPC} title="" className="img-fluid" alt="..." />
              </button>
            </div>

            <div class="col-sm-4 col-12  ">
              <button
                class="btn logo-btn close-button text-center"
                type="button"
                data-toggle="collapse"
                data-target="#SBTi"
                aria-expanded="false"
                aria-controls=""
              >
                {' '}
                <img src={SBTi} title="" className="img-fluid" alt="..." />
              </button>
            </div>

            <div class="col-sm-4 col-12  ">
              <button
                class="btn logo-btn close-button text-center"
                type="button"
                data-toggle="collapse"
                data-target="#How2Recycle"
                aria-expanded="false"
                aria-controls=""
              >
                {' '}
                <img
                  src={How2Recycle}
                  title=""
                  className="img-fluid"
                  alt="..."
                />
              </button>
            </div>
          </div>

          <div
            class="row mb-5 collapse"
            id="How2Recycle"
            data-parent="#sourcinggroup"
          >
            {/* IronMatt (The Matthew Larson Foundation)  */}
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button
                  class="btn close-button"
                  type="button"
                  data-toggle="collapse"
                  data-target="#How2Recycle"
                  aria-expanded="false"
                  aria-controls=""
                >
                  X
                </button>
                <p>
                  How2Recycle works to improve recycling by creating a clear,
                  well-understood, and nationally harmonized recycling label and
                  influencing package design. The label is the first and only
                  label designed to apply to all packaging material and format
                  types, in accordance with the Federal Trade Commission’s
                  guidelines concerning environmental marketing claims.
                </p>
                <p>
                  <a target="_blank" href="https://how2recycle.info/">
                    Learn more about How2Recycle.
                  </a>
                </p>
              </BorderedBox>
            </div>
          </div>

          <div class="row mb-5 collapse" id="SBTi" data-parent="#sourcinggroup">
            {/* IronMatt (The Matthew Larson Foundation)  */}
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button
                  class="btn close-button"
                  type="button"
                  data-toggle="collapse"
                  data-target="#SBTi"
                  aria-expanded="false"
                  aria-controls=""
                >
                  X
                </button>
                <p>
                  The Science Based Targets initiative (SBTi) is a collaboration
                  between CDP, the United Nations Global Compact, World
                  Resources Institute (WRI) and the World Wide Fund for Nature
                  (WWF). The SBTi promotes best practice by assessing corporate
                  GHG reduction goals against the latest climate science.
                </p>
                <p>
                  <a target="_blank" href="https://sciencebasedtargets.org/">
                    Learn more about the Science Based Targets initiative.{' '}
                  </a>
                </p>
              </BorderedBox>
            </div>
          </div>

          <div class="row mb-5 collapse" id="SPC" data-parent="#sourcinggroup">
            {/* IronMatt (The Matthew Larson Foundation)  */}
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button
                  class="btn close-button"
                  type="button"
                  data-toggle="collapse"
                  data-target="#SPC"
                  aria-expanded="false"
                  aria-controls=""
                >
                  X
                </button>
                <p>
                  The Sustainable Packaging Coalition (SPC) brings together
                  businesses, educational institutions, and government agencies
                  to collectively strengthen and advance the business case for
                  more sustainable packaging.
                </p>
                <p>
                  <a target="_blank" href="https://sustainablepackaging.org/">
                    Learn more about the Sustainable Packaging Coalition.{' '}
                  </a>
                </p>
              </BorderedBox>
            </div>
          </div>

          <div class="row mb-5 text-center">
            <div class="col-sm-4 col-12 pl-5 pr-5">
              <button
                class="btn logo-btn close-button"
                type="button"
                data-toggle="collapse"
                data-target="#Textile"
                aria-expanded="false"
                aria-controls=""
              >
                {' '}
                <img
                  src={corporateimage10}
                  title=""
                  className="img-fluid"
                  alt="..."
                />
              </button>
            </div>

            <div class="col-sm-4 col-12 pl-5 pr-5">
              <button
                class="btn logo-btn close-button margin-topp"
                type="button"
                data-toggle="collapse"
                data-target="#zdhc"
                aria-expanded="false"
                aria-controls=""
              >
                {' '}
                <img
                  src={corporateimage11}
                  title=""
                  className="img-fluid"
                  alt="..."
                />
              </button>
            </div>

            <div class="col-sm-4 col-12 pl-5 pr-5">
              <button
                class="btn logo-btn close-button margin-topp"
                type="button"
                data-toggle="collapse"
                data-target="#CanopyStyle"
                aria-expanded="false"
                aria-controls=""
              >
                {' '}
                <img
                  src={corporateimage12}
                  title=""
                  className="img-fluid"
                  alt="..."
                />
              </button>
            </div>
          </div>

          <div
            class="row mb-5 collapse"
            id="Textile"
            data-parent="#sourcinggroup"
          >
            {/* IronMatt (The Matthew Larson Foundation)  */}
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button
                  class="btn close-button"
                  type="button"
                  data-toggle="collapse"
                  data-target="#Textile"
                  aria-expanded="false"
                  aria-controls=""
                >
                  X
                </button>
                <p>
                  Textile Exchange is a global nonprofit that creates leaders in
                  the sustainable fiber and materials industry. The organization
                  manages and promotes a suite of leading industry standards, as
                  well as, collects and publishes critical industry data and
                  insights that enable brands and retailers to measure, manage
                  and track their use of preferred fiber and materials. <br />
                  <br />
                  Learn more about{' '}
                  <a target="_blank" href="https://textileexchange.org/">
                    Textile Exchange
                  </a>
                  .
                </p>
              </BorderedBox>
            </div>
          </div>

          <div class="row mb-5 collapse" id="zdhc" data-parent="#sourcinggroup">
            {/* IronMatt (The Matthew Larson Foundation)  */}
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button
                  class="btn close-button"
                  type="button"
                  data-toggle="collapse"
                  data-target="#zdhc"
                  aria-expanded="false"
                  aria-controls=""
                >
                  X
                </button>
                <p>
                  ZDHC enables brands and retailers in the textile, apparel, and
                  footwear industries to implement sustainable chemical
                  management best practices across the global value chain.
                  <br />
                  <br />
                  Learn more about{' '}
                  <a target="_blank" href="https://www.roadmaptozero.com">
                    ZDHC
                  </a>
                  .
                </p>
              </BorderedBox>
            </div>
          </div>

          <div
            class="row mb-5 collapse"
            id="CanopyStyle"
            data-parent="#sourcinggroup"
          >
            {/* IronMatt (The Matthew Larson Foundation)  */}
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button
                  class="btn close-button"
                  type="button"
                  data-toggle="collapse"
                  data-target="#CanopyStyle"
                  aria-expanded="false"
                  aria-controls=""
                >
                  X
                </button>
                <p>
                  The CanopyStyle initiative, from environmental non-profit
                  Canopy, unites hundreds of global fashion brands, retailers,
                  and viscose producers to collaborate on finding alternative
                  fibers for viscose and rayon textiles, advocating for
                  ecosystem conservation, and keeping Ancient and Endangered
                  Forests standing and out of our wardrobes.
                  <br />
                  <br />
                  Learn more about{' '}
                  <a
                    target="_blank"
                    href="https://canopyplanet.org/campaigns/canopystyle"
                  >
                    CanopyStyle
                  </a>
                  .
                </p>
              </BorderedBox>
            </div>
          </div>

          <div class="row mb-5 text-center">
            <div class="col-sm-4 col-12 pl-5 pr-5">
              <button
                class="btn logo-btn close-button"
                type="button"
                data-toggle="collapse"
                data-target="#Pack4Good"
                aria-expanded="false"
                aria-controls=""
              >
                {' '}
                <img
                  src={corporateimage13}
                  title=""
                  className="img-fluid"
                  alt="..."
                />
              </button>
            </div>
          </div>

          <div
            class="row mb-5 collapse"
            id="Pack4Good"
            data-parent="#sourcinggroup"
          >
            {/* IronMatt (The Matthew Larson Foundation)  */}
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button
                  class="btn close-button"
                  type="button"
                  data-toggle="collapse"
                  data-target="#Pack4Good"
                  aria-expanded="false"
                  aria-controls=""
                >
                  X
                </button>
                <p>
                  Pack4Good is Canopy’s initiative that collaborates with
                  hundreds of companies to reduce waste, innovate, and ensure
                  the world’s Ancient and Endangered Forests aren’t cut down to
                  make delivery boxes and takeout containers.
                  <br />
                  <br />
                  Learn more about{' '}
                  <a
                    target="_blank"
                    href="https://canopyplanet.org/campaigns/pack4good"
                  >
                    Pack4Good
                  </a>
                  .
                </p>
              </BorderedBox>
            </div>
          </div>
        </div>
        {/* end environmental */}
      </Layout>
    )
  }
}

export default ResponsibleSourcing
